import React from "react";
import "./Contact.scss";
import Form from "./Form/Form";
import Email from "./Icons/Whatsapp/Email";
import Whatsapp from "./Icons/Whatsapp/Whatsapp";
import Link from "./Links/Link/Link";

const Contact = () => {
  return (
    <section id="contact" className="full-page col-start">
      <Form />
      <div className="card" id="contact-links">
        <Link text="072 672 1763" uri="tel:+27726721763">
          <Whatsapp />
        </Link>
        <Link text="info@kritmiss.com" uri="mailto:info@kritmiss.com">
          <Email />
        </Link>
      </div>
    </section>
  );
};

export default Contact;
