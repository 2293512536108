import React from "react";
import "./App.scss";
import Home from "./Home/Home";
import "./Variables.scss";

const App = () => {
  return <Home />;
};

export default App;
