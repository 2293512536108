import React from "react";
import { IMAGE_BASE_URI } from "../../../Constants";
import "./HeaderLink.scss";

const HeaderLink = ({ link, linkName, children }) => {
  return (
    <div className="col-start header-link card">
      <a href={link}>
        {children}
        <h2>{linkName}</h2>
      </a>
    </div>
  );
};

export default HeaderLink;
