import React from "react";

const Failed = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-width="2"
        d="M20 20L4 4m16 0L4 20"
      />
    </svg>
  );
};

export default Failed;
