import React from "react";
import { BLURB, IMAGE_BASE_URI } from "../../Constants";
import "./Header.scss";
import HeaderLink from "./HeaderLink/HeaderLink";
import Bat from "./SVGs/Bat";
import FireElemental from "./SVGs/Fire-Elemental";

const Header = () => {
  return (
    <section id="header" className="full-page col-start">
      <div className="card" id="card-logo">
        <div className="main-logo col-start">
          <img src={`${IMAGE_BASE_URI}Logo-colour.png`} alt="" />
        </div>
      </div>
      <div className="card" id="card-blurb">
        <p>{BLURB}</p>
      </div>

      <div className="row-center" id="card-links">
        <HeaderLink linkName="Learn More" link="#about">
          <Bat />
        </HeaderLink>
        <HeaderLink linkName="Get in touch" link="#contact">
          <FireElemental />
        </HeaderLink>
        {/* Remeber to comment me out */}
        {/* <HeaderLink
          linkName="Claim Adventure"
          link=""
          imgFileName="Witch-hat.png"
        /> */}
      </div>
    </section>
  );
};

export default Header;
