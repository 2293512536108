import React from "react";
import About from "./About/About";
import BackGroundImg from "./BackGroundImg/BackGroundImg";
import Contact from "./Contact/Contact";
import Header from "./Header/Header";
import "./Home.scss";

const Home = () => {
  return (
    <>
      <BackGroundImg />
      <Header />
      <About />
      <Contact />
    </>
  );
};

export default Home;
