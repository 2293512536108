import React, { useEffect, useState } from "react";
import { IMAGE_BASE_URI } from "../../Constants";
import "./BackGroundImg.scss";

const BackGroundImg = () => {
  const MOBI_IMG = "background-calendar-mobi.jpg";
  const WEB_IMG = "background-calendar.jpg";
  const [width, setWidth] = useState(window.innerWidth);
  const [flex, setFlex] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    updateFlex(width);
  }, [width]);

  function updateFlex(width) {
    if (width < 500) {
      setFlex(true);
    } else {
      setFlex(false);
    }
  }
  return (
    <img
      className="backdrop"
      src={`${IMAGE_BASE_URI}${flex ? MOBI_IMG : WEB_IMG}`}
    />
  );
};

export default BackGroundImg;
