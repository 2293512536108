import "./ProgDisplay.scss";
import React from "react";

const ProgDisplay = ({ children, text, className, hidden }) => {
  return (
    <div
      className={`row-start prog-display ${className} ${hidden && "hidden"}`}
    >
      {children}
      <p>{text}</p>
    </div>
  );
};

export default ProgDisplay;
